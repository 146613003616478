
import {
    defineComponent, onBeforeMount, onBeforeUnmount, reactive, computed,
} from 'vue';
import TrackedItem from '@/domain/TrackedItem';
import TrackedItemService from '@/services/tag-scanning/TrackedItemService';
import ItemService from '@/services/ItemService';
import Item from '@/domain/Item';
import useValidator from '@/validation/useValidator';
import { useNotification } from '@/composable/useNotifications';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import TagListener from '@/modules/floortrak/services/TagListener';
import ImageNotFound from '@/components/ImageNotFound.vue';
import Screen from '@/components/layout/Screen.vue';
import { ItemType } from '@/domain/enums/ItemType';
import ItemPicker from '@/components/ItemPicker.vue';
import TagInput from '@/components/inputs/TagInput.vue';
import SmartTrakFooter from '@/components/SmartTrakFooter.vue';
import Thumbnail from '@/components/Thumbnail.vue';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';

type State = {
    showScannedTagsModal: boolean;
    item: Item;
    newTag: string;
    tags: Array<{ barcode: string, success: boolean }>;
    tagErrorMessages: Array<{ barcode: string, errors: string }>;
    busy: boolean;
    errorMsg: string;
    showMore: boolean;
    showConfirmModal: boolean;
    allItems: Array<Item>;
    loadingItems: boolean;
}

export default defineComponent({
    name: 'inventory-add-tag',
    components: {
        Thumbnail,
        Screen,
        BFormInput,
        ImageNotFound,
        ItemPicker,
        TagInput,
        SmartTrakFooter,
    },
    props: {},
    setup() {
        const itemService = new ItemService();
        const trackedItemService = new TrackedItemService();
        const { showValidationError, showError } = useNotification();
        const tagListener = new TagListener(addTags);

        const { validateForm, validationResult } = useValidator<TrackedItem>('update-tracked-item');

        const state = reactive<State>({
            showScannedTagsModal: false,
            item: new Item(),
            newTag: '',
            tags: [],
            tagErrorMessages: [],
            busy: false,
            errorMsg: '',
            showMore: false,
            showConfirmModal: false,
            allItems: [],
            loadingItems: true,
        });

        const saveable = computed(() => !state.busy && state.newTag && state.item.id);
        const tagErrorCount = computed(() => state.tags.filter((x) => !x.success).length);

        function resetState() {
            state.newTag = '';
            state.item = new Item();
            state.tags = [];
        }

        function addItem(data: { item: Item }): void {
            if (!data.item) {
                resetState();
                return;
            }

            state.item = data.item;
        }

        function duplicateBarcode(barcode: string) {
            if (state.tags.find((x) => x.barcode === barcode)) {
                showError(`${getTranslation('core.validation.alreadyScannedBarcode')}: ${barcode}`);
                return true;
            }

            return false;
        }

        function tagBarcodeText(tag: { barcode: string, success: boolean }) {
            return (tag.success ? tag.barcode : `${tag.barcode} - ${state.tagErrorMessages.find((error) => error.barcode === tag.barcode)!.errors}`);
        }

        async function addTags(barcodes: Array<string>) {
            if (!saveable.value) return;

            state.busy = true;
            barcodes.forEach(async (barcode) => {
                const newTrackedItem = new TrackedItem();
                newTrackedItem.itemId = state.item.id;
                newTrackedItem.barcode = barcode;

                validateForm(newTrackedItem);

                if (validationResult.isValid && !duplicateBarcode(barcode)) {
                    const response = await trackedItemService.createTrackedItem(newTrackedItem);
                    const { success, errorList } = response;

                    state.tags.unshift({ barcode, success });

                    if (!success && errorList) {
                        const errors = Object.values(errorList).toString();
                        state.tagErrorMessages.push({ barcode, errors });
                    }
                } else {
                    showValidationError(validationResult.errorList);
                }
            });
            state.newTag = '';
            state.busy = false;
        }

        async function addTag(barcode: string) {
            await addTags([barcode]);
        }

        async function getAllItems(): Promise<Array<Item>> {
            const result = await itemService.getAllItems();

            if (result.success) {
                return result.items;
            }

            return [];
        }

        onBeforeMount(async () => {
            tagListener.startListener();
            state.allItems = await getAllItems();
            state.loadingItems = false;
        });

        onBeforeUnmount(() => {
            tagListener.stopListener();
        });

        return {
            state,
            addItem,
            addTag,
            validationResult,
            ItemType,
            saveable,
            tagErrorCount,
            tagBarcodeText,
            resetState,
            getTranslation,
            getTitleCaseTranslation,
        };
    },
});
